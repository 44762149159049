.infos{
    background-color:$lightgrey;
    padding-top: 50px; 
    padding-bottom:500px;
    .infos-inner{
    display:flex;
    // justify-content:space-between;
    margin-bottom:50px;
    flex-wrap:wrap;

    .icon-document{
        background-image: url("/wp-content/themes/acasa/img/icons/icon-document-black.svg");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        width:20px; 
        height:20px;

        margin-top:10px;
    }
    h2{
        margin:0;
    }
    .col{ 
        margin:0;
        &:first-child{
            width:40px;
            @media screen and (max-width: $tabletsize) {
             
                margin-bottom:20px !important;
            }
        } 
        &:last-child{
            width:calc(80% - 40px);
            @media screen and (max-width: $tabletsize) {
                width:100%;
                 }
        }
    }
}
}