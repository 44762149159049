.hero {
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-position: center right;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: $tabletsize) {
    background-position: right -250px center; 

    
  }



  .hero-overlay {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    .hero-content {
      color: $contrastcolor;
      position: relative;
      z-index: 1;
      h1 {
        margin: 0;
      }
      h2 {
        margin: 0;
      }

      h3 {
        color: $contrastcolor;
        font-weight: 900;
        margin: 0;
        letter-spacing: 2.4pt;
        font-size: 14px;
      }

      .btn {
        margin-top: 50px;
      }
    }

    .hero-color-overlay {
      position: absolute;

      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      &.blue {
        background: rgb(19, 42, 79);
        background: linear-gradient(
          29deg,
          rgba(19, 42, 79, 1) 0%,
          rgba(19, 42, 79, 0.6) 47%,
          rgba(19, 42, 79, 0.1) 100%
        );
        .hero-content {
          color: white;
        }
      }
      &.green {
        background: rgb(93, 204, 169);
        background: linear-gradient(
          29deg,
          rgba(93, 204, 169, 0) 0%,
          rgba(93, 204, 169, 0.5564819677871149) 47%,
          rgba(93, 204, 169, 1) 100%
        );
        .hero-content {
          color: white;
        }
      }
    }
  }
}
