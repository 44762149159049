.reasons{
    .reasons-inner{
        display:flex;
        justify-content: space-between;
        flex-wrap:wrap;
        .reason{
            // width:calc(20% - 5px);
            width:20%;
            background-color: #f2f7f6;
            min-height:50px; 
            @media screen and (max-width: $desktopsize) {
                width: 50%;
                 
              }
            @media screen and (max-width: $mobilesize) {
                width: 100%;
                
              }
            &:nth-child(2n){
                background-color:white;
            
            } 
            .number{
                font-size: 48px;
                margin: 20px;
                margin-bottom:0;
                color:#3ca690; 
            }
            .reason-text{
                margin: 20px;

            }
        }
    } 
}


 