.application {
  display: flex;
  flex-wrap: wrap;
  .col {
    margin:0;
    &:first-child {
      width: 25%;
      @media screen and (max-width: $tabletsize) {
        width: 100%;
      }
    }
    &:last-child {
      width: 75%;
      @media screen and (max-width: $tabletsize) {
        width: 100%;
      }
    }
  }

  .af-fields {
    display: flex;
    flex-wrap: wrap;
    .af-field-vorname {
      width: 50%;
      @media screen and (max-width: $tabletsize) {
        width: 100%;
      }
    }
    .af-field-nachname {
      width: 50%;
      @media screen and (max-width: $tabletsize) {
        width: 100%;
      }
    }

    .af-field-strase {
      width: 80%;
    }
    .af-field-hausnummer {
      width: 20%;
    }
    .af-field-postleitzahl {
      width: 30%;
    }
    .af-field-stadt {
      width: 70%;
    }
    .af-field-email {
      width: 100%;
    }
    .af-field-telefonnummer {
      width: 100%;
    }
    .af-field-stelle {
      width: 100%;
    }
    .af-field-stunden {
      width: 100%;
    }

    .af-submit {
      width: 100%;
      @media screen and (max-width: $tabletsize) {
        width: 100%;
      } 
    }
    button[type="submit"] {
      @media screen and (max-width: $tabletsize) {
        width: 100%;
      }
    }
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
