$mobilesize:600px;
$tabletsize:768px;
$middesktop:1024px;
$desktopsize:1200px;

$white:white;
$black:#000;
$grey:#979797;
$lightgrey:#F4F6F8;
//$contrastcolor: #1E3D70; 
$contrastcolor: #132a4f;
// $lightgreen:#5DCCA9;
$lightgreen:#d3e8db;
