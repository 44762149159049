.contact{
    display:flex;
    flex-wrap: wrap;
    .col{
      margin:0;
    &:first-child{
        width:25%;
        @media screen and (max-width: $tabletsize) {
           width:100%; 
            }

    }
    &:last-child{
        width:75%;
        @media screen and (max-width: $tabletsize) {
            width:100%; 
             }
    }

    .af-submit {
        width: 100%;
        @media screen and (max-width: $tabletsize) {
          width: 100%;
        }
      }
      button[type="submit"] {
        @media screen and (max-width: $tabletsize) {
          width: 100%;
        }
      }
}
}