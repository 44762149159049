.page-header{
    .page-header-inner{
    background-image: url("/wp-content/themes/acasa/img/header_logo.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width:100%;
    height:50vh;
    min-height:400px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    @media screen and (max-width: $tabletsize) {
     width:90%;
      }
    h1{
        margin:0;
        color: #132a4f;
    }
    h3{
        color: #132a4f;
        font-weight: 900;
        margin: 0;
        letter-spacing: 2.4pt;
        font-size: 14px;
        text-transform: uppercase;
    }
}
} 