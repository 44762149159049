@font-face {
  font-family: "Roboto";
  src: url("../../fonts/Roboto-BlackItalic.woff2") format("woff2"),
    url("../../fonts/Roboto-BlackItalic.woff") format("woff");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("../../fonts/Roboto-Black.woff2") format("woff2"),
    url("../../fonts/Roboto-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("../../fonts/Roboto-ThinItalic.woff2") format("woff2"),
    url("../../fonts/Roboto-ThinItalic.woff") format("woff");
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("../../fonts/Roboto-Bold.woff2") format("woff2"),
    url("../../fonts/Roboto-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("../../fonts/Roboto-LightItalic.woff2") format("woff2"),
    url("../../fonts/Roboto-LightItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("../../fonts/Roboto-Light.woff2") format("woff2"),
    url("../../fonts/Roboto-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("../../fonts/Roboto-Italic.woff2") format("woff2"),
    url("../../fonts/Roboto-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("../../fonts/Roboto-Regular.woff2") format("woff2"),
    url("../../fonts/Roboto-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("../../fonts/Roboto-MediumItalic.woff2") format("woff2"),
    url("../../fonts/Roboto-MediumItalic.woff") format("woff");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("../../fonts/Roboto-BoldItalic.woff2") format("woff2"),
    url("../../fonts/Roboto-BoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("../../fonts/Roboto-Medium.woff2") format("woff2"),
    url("../../fonts/Roboto-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto Condensed";
  src: url("../../fonts/RobotoCondensed-Light.woff2") format("woff2"),
    url("../../fonts/RobotoCondensed-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto Condensed";
  src: url("../../fonts/RobotoCondensed-LightItalic.woff2") format("woff2"),
    url("../../fonts/RobotoCondensed-LightItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Roboto Condensed";
  src: url("../../fonts/RobotoCondensed-Bold.woff2") format("woff2"),
    url("../../fonts/RobotoCondensed-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto Condensed";
  src: url("../../fonts/RobotoCondensed-Regular.woff2") format("woff2"),
    url("../../fonts/RobotoCondensed-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto Condensed";
  src: url("../../fonts/RobotoCondensed-BoldItalic.woff2") format("woff2"),
    url("../../fonts/RobotoCondensed-BoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Roboto Condensed";
  src: url("../../fonts/RobotoCondensed-Italic.woff2") format("woff2"),
    url("../../fonts/RobotoCondensed-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}
 



p, ul li {
  line-height: 28px;
  font-size: 16px;
  font-weight: 300;
    letter-spacing: 0.3pt;

}



h1,
h2,
h3,
h4 {
  font-family: "Roboto Condensed";

}

h1 {
  font-size: 80px;
  font-weight: 200;
  line-height: 80px;
  @media screen and (max-width: $tabletsize) {
    font-size: 40px;
    line-height: 40px;
  }
}

h2 {
  font-size: 40px; 
  font-weight: 200;
}


h3 {
  font-size: 22px;
  line-height:30px;
  font-weight: 400;
  margin-bottom:35px;
  letter-spacing: .4pt;

}

.inner-wrapper ul{ 
    padding:0;
    margin:0;
    li{
        padding:0;
        margin:0;
        padding-left:30px;
        position:relative;
        list-style: none; 
        margin-top: 20px;
        margin-bottom: 20px;
        &:before{
            content:'';
            position: absolute;
            top: 4px; 
           
            left: 0;
            margin: auto 0;
            background-image: url("/wp-content/themes/acasa/img/icons/icon-checkmark-green.svg");
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            width:20px;
            height:20px;
        }
}
}
