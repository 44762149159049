.teammember{
    display:flex;
    justify-content:space-between;
    @media screen and (max-width: $tabletsize) {
        flex-direction: column;         
       }
    &:nth-child(2n){
        flex-direction: row-reverse;
        @media screen and (max-width: $tabletsize) {
            flex-direction: column;         
           }
    } 
    .col{
        width:50%;
        display:flex;
        justify-content:center;
        align-items:center;
        margin:0;
        flex-wrap: wrap;
        @media screen and (max-width: $tabletsize) {
           width:100% !important;         
          }

        .portrait{
            width:100%;
            aspect-ratio: 1 / 1; 
            background-size:cover;
            background-position:center;
            background-repeat: no-repeat;
        }
    }
    .teammember-inner{
        width:calc(100% - 40px);
        @media screen and (max-width: $tabletsize) {
           padding-top:30px;
           padding-bottom:30px;       
           }
        h2, h3{
            margin:0;
        }
    }
}