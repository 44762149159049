.teaser{
    background-color:#132a4f;
    // border-radius: 20px;
    // height:50vh; 
    min-height:500px;
    width:100%;
    // max-width:1300px;
    display:flex;
    justify-content: space-between;
    // -webkit-box-shadow: 0px 5px 10px -2px #000000; 
    // box-shadow: 0px 5px 10px -2px #000000;
    // margin-top:100px;
    margin-bottom:100px;
    @media screen and (max-width: $tabletsize) {
        margin-bottom:0;
        flex-direction:column;
            
    }
    .col{
        width:50%; 
        color:white;
        display: flex;
        justify-content:center;
        align-items: center;

        background-repeat: no-repeat;
        background-position: center;
        background-size:cover;
        aspect-ratio: 1 / 1;
        padding-bottom: 20px; 
        @media screen and (max-width: $tabletsize) {
            width:100%;
          
                
        }
        &.image{
            margin:0;
           
            
            
        }
        h1,h2,h3, h4{
            color:#3ca690;
            margin:0;
        }
        h2{
            margin-bottom: 20px;
        }
 
        .inner{
            width:calc(100% - 80px);
            @media screen and (max-width: $tabletsize) {
              padding-top:30px;
              padding-bottom:30px;         
            }
        }
        .btn{
            margin-top:50px;
         background-color:#d3e8db;
              
        }
    }
}