.video{
    width: 100%;
    max-width: 1300px;
    position: relative;
    margin-top:100px;
    margin-bottom:100px;
    .video-overlay{
        position:absolute;
        z-index:1;
        width:100%;
        height:100%;
        top:0;
        left:0;
        right:0;
        bottom:0;
        background-color:rgba(0,0,0,.5);
    
        display:flex;
        justify-content:center; 
        align-items:center;
    }

    .btn-play{
        width:120px;
        height:120px;
        background-color:white;
        border-radius:60px;
        cursor:pointer;
        display: flex; 
        justify-content: center;
        align-items:center;
        .btn-play-icon{
            width:40px;
            height:40px;
            background-image: url("/wp-content/themes/acasa/img/icons/icon-play-green.svg");
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
        }
    
    }
}