.intro{
    background-color: #132a4f;
    display: flex;
    justify-content:center; 
    .intro-inner{
        padding-top:20px;
        padding-bottom:20px;
        width:calc(100% - 40px);
        display: flex;
        justify-content: space-between;
        flex-wrap:wrap;
    }
    .intro-text{
        width:calc(60% - 40px);
        @media screen and (max-width: $desktopsize) {
            width: 100%;
             
          }
      
    }
    .intro-image{
        width:40%;
        @media screen and (max-width: $desktopsize) {
            width: 100%;
             
          }
        .inner-image{
          
        margin-top:-100px;
        @media screen and (max-width: $desktopsize) {
            margin-top:0;
             
          }
        aspect-ratio:1/1;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        }
    }
    h3,
    h2{
        color: #3ca690;
    }
    p{
        color:white;
    }
}