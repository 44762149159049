.af-field {
    border-top:none !important;
    padding-left: 0;
    padding-right: 0;
  
    select,
    input[type=email],
    input[type=text],
    input[type=number]{
        height: 45px; 
        line-height: 45px;
        border: none;
  
        outline:none;
        background-color:#f2f7f6;
        border:1px solid #f0f0f0;

    } 
 
   textarea{
        height: 200px;  
        resize: none; 
        outline:none;
        background-color:#f2f7f6;
        border: none;
       
        border:1px solid #f0f0f0;
    } 
    ul {
        padding:0;
        margin:0;
        li{
            padding:0 !important;
            &:before{
                display: none;
            }
        }
    }

    &.af-field-type-radio{
        display:flex;
        width: 100%;
        justify-content: space-between;
        .acf-radio-list{
            display: flex;
        }
    }

    .message{
        font-size: 9pt;
        color: #888;
    }


}  
.af-field-type-true-false{
//     display: flex;
//     justify-content: space-between;
//     width: 100%;
}

.af-submit{
    padding:0 !important;
}