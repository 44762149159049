header {
  width: 100%;
  height: 60px;

  position: fixed;
  z-index: 100;
  background-color: rgba(255,255,255,0);
  border-bottom: 1px solid rgba(240,240,240,0);
  transition: all .5s ease;
  &.scrolled{
    background-color: rgba(255,255,255,1);
    border-bottom: 1px solid rgba(240,240,240,1);
  }

  .header-inner {
    display:flex;
    justify-content:space-between;
    align-items:center;
   

    .logo {

      //hero
      //background-image: url("/wp-content/themes/acasa/img/acasa-pflege-minimal-white.svg");

     background-image: url("/wp-content/themes/acasa/img/acasa-pflege-minimal.svg");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;

      display: block;
      // height: 160px;
      width: 120px;
      height:45px;

      // float:left;
      position: relative;

    }

    .batch{
    height: 61px;
    width: 200px;
    // background-color: red;

    display: block;
    flex-grow: 1;
    position:relative;
    .batch-icon{
      position:absolute;
      height: 96px;
      width: 200px;
      background-image: url("/wp-content/themes/acasa/img/RZ-WEB-AAP-ausgezeichnet-2025.png");
      background-size: contain;
      background-repeat: no-repeat; 
      background-position: center;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      @media screen and (max-width: $tabletsize) {
        height: 61px;
        width: 80px;
        left:auto;
        }
    }
    }
  }
}


.home{
  header .header-inner .logo {
    background-image: url("/wp-content/themes/acasa/img/acasa-pflege-minimal-white.svg");

  }
  header.scrolled .header-inner .logo {
    background-image: url("/wp-content/themes/acasa/img/acasa-pflege-minimal.svg");
  }
}

.menu-wrapper{
  display: flex;
  align-items: center;
  .btn{
    height:35px;
    font-size:14px; 
    font-weight:900;
    margin-right:20px;
    background-color:$contrastcolor; 
    color:white;
    box-shadow: none;
    text-transform: none;
    @media screen and (max-width: $tabletsize) {
    display:none;   
    }
    .btn-icon{
      background-image: url("/wp-content/themes/acasa/img/icons/icon-arrow-right-white.svg");
    }
  }
  
}



.hamburger {
  // padding: 15px 15px;
  // padding-left: 0px;
  // display: inline-block;
  // cursor: pointer;
  // transition-property: opacity, filter;
  // transition-duration: 0.15s;
  // transition-timing-function: linear;
  // font: inherit;
  // color: inherit;
  // text-transform: none;
  // background-color: transparent;
  // border: 0;
  // margin: 0;
  // overflow: visible;
  // position: relative;

  // width: 40px; 
  // height: 24px;


  font: inherit;
    display: inline-block;
    overflow: visible;
    margin: 0;
    padding: 15px;
    cursor: pointer;
    transition-timing-function: linear;
    transition-duration: .15s;
    transition-property: opacity,filter;
    text-transform: none;
    color: inherit;
    border: 0;
    background-color: transparent;
    z-index: 10000;

}
.hamburger:hover {
  opacity: 0.7;
}

.hamburger-box {
  width: 25px;
  height: 20px;
  margin-top: 6px;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -2px;
}
.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
  width: 25px;
  height: 2px;
  background-color: $contrastcolor; 
  border-radius: 4px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s; 
  transition-timing-function: ease;
}

.home{
  .hamburger-inner,
  .hamburger-inner::before,
  .hamburger-inner::after {

    background-color: white; 
  }
  .scrolled{
    .hamburger-inner,
    .hamburger-inner::before,
    .hamburger-inner::after {
  
      background-color: $contrastcolor;  
    }

  }
}

.is-active{
  // .hamburger-inner,
  .hamburger-inner::before,
  .hamburger-inner::after {

    background-color: white !important;
  }
}

.hamburger-inner::before,
.hamburger-inner::after {
  content: "";
  display: block;
}
.hamburger-inner::before {
  top: 7px;
}
.hamburger-inner::after {
  top: 14px;
}

.hamburger--spring .hamburger-inner {
  top: 2px;
  transition: background-color 0s linear;
}
.hamburger--spring .hamburger-inner::before {
  top: 7px;
  transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
    transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--spring .hamburger-inner::after {
  top: 14px;
  transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
  transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spring.is-active .hamburger-inner {
  transition-delay: 0.22s;
  background-color: transparent;
}
.hamburger--spring.is-active .hamburger-inner::before {
  top: 0;
  transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
  transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
  transform: translate3d(0, 10px, 0) rotate(45deg);
}
.hamburger--spring.is-active .hamburger-inner::after {
  top: 0;
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
    transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
  transform: translate3d(0, 10px, 0) rotate(-45deg);
}

// .hamburger {
//   display: none;
// }

.show {
  display: block !important;
}
