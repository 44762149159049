.blog{
 
.grid-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px; /* Abstand zwischen den Spalten und Zeilen */
    @media screen and (max-width: $desktopsize) {
      grid-template-columns: repeat(2, 1fr); 
    } 

    @media screen and (max-width: $tabletsize) {
      display:flex;
      flex-wrap:wrap;
      gap:0; 
    }  
    .grid-item {
        height: 100%;
        display: flex; 

        .entry-card-inner{
            color: black;
            .thumbnail{
                img{
                    width: 100%;
                    height: auto;
                
                }
            }
        }
        &:before{ 
            content:'';
            display: none;
        }
        @media screen and (max-width: $tabletsize) {
        width:100%; 
        margin-bottom:30px; 
      
        } 
   
    }
  }
}

.single-thumbnail{
    img{
        width: 100%;
        height: auto;
    
    }
}
.single-date{
    margin-top:50px;
}