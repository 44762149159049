html {
  width: 100%;
  height: 100%;
  position: fixed;
  overflow: auto;
  font-display: swap;
}

body {
  width: 100%;
  height: 100%;

  font-family: 'Roboto', sans-serif;
  display: block;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  position: fixed;
  overflow: auto;
}

#wrapper {
  width: 100%;
  margin: auto;
  overflow: hidden;
  position: relative;
  // .inner-wrapper {
  //   width: 80%;
  //   background-color: rgba(255, 255, 255, 0.7);
  //   margin: 2.5%;
  //   margin-bottom: 0px;
  //   padding-bottom: 30px;

  // }
  .inner-wrapper{
    // padding-top: 80px;
    a{
      color:#3ca690;
    }
  }
}

.center{    
  width: 80%;
    max-width: 1200px;
margin:auto;
@media screen and (max-width: $tabletsize) {
  width: 90%;
  
}
}
.center-text{
  max-width: 800px;
}

.content-head {
  margin-bottom: 100px;
}

.blue-bg {
  background-color: $contrastcolor;
}
button[type=submit],
.btn{
  outline: none;
  border: none;

  cursor:pointer;

  font-family: "Roboto Condensed";

  letter-spacing: 1.2pt;
  font-size: 18px;
  font-family: "Roboto Condensed";
  font-weight: 400;

  background-color: #132a4f;
  color:white;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  // text-transform:uppercase;

  padding-left: 20px;
  padding-right: 20px;
  border-radius: 55px; 
  min-width: 140px;
  height: 55px;
  box-shadow: 0 3px 5px 2px rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease;
  span{
    width:calc(100% - 20px);
  }

  .btn-icon{ 
    background-image: url("/wp-content/themes/acasa/img/icons/icon-arrow-right-green.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width:20px;
    height:20px;
    margin-left: 10px;
    position: relative;
    right:0;
    transition: all 0.2s ease;

  }
  &:hover{
    box-shadow: 0 3px 5px 2px rgba(0, 0, 0, 0);
    .btn-icon{
      right:-5px;
    }
  }

}

.tags{
  width:100%;
  margin-top:20px;
  .tag {
      background-color: $lightgrey;
      height: 15px;
      border-radius: 20px;
      padding: 10px;
      padding-left:20px;
      padding-right:20px; 
      display: inline-block;
      font-size:9pt;
      margin-bottom:10px;
      .tag-inner{
          display: flex;
          justify-content: space-between;
      }
      .tag-icon{
          background-image: url("/wp-content/themes/acasa/img/icons/icon-arrow-up-blue.svg");
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
          width:15px;
          height:15px; 
          &.icon-location{
            background-image: url("/wp-content/themes/acasa/img/icons/icon-location.svg");
          }
          &.icon-time{
            background-image: url("/wp-content/themes/acasa/img/icons/icon-time.svg");
          }

          &.icon-date{
            background-image: url("/wp-content/themes/acasa/img/icons/icon-date.svg");
          }
      }
      span{
          margin-left: 10px;
      }
    }
}

.social-wrapper{
 
  float: right;
  margin-top: 18px;

 
  display:flex;
  justify-content:space-between;
  width:60px;
  height: 25px;
 }
.icon {
  width: 25px;
  height: 25px;
  display: block;
  // float: right;
  // margin-top: 18px;
  // margin-left: 20px;
}


.clearfix::before,
.clearfix::after {
  content: " ";
  display: table;
}

.clearfix::after {
  clear: both;
}

.clearfix {
  *zoom: 1; /* Für den IE6 und IE7 */
}

@media screen and (max-width: $tabletsize) {
  .col {
    width: 100% !important;
    margin: 0 !important;
  }
}

.four-three {
  position: relative;
  overflow: hidden;
}
.four-three:before {
  display: block;
  content: " ";
  width: 100%;
  padding-top: 67%;
}
.four-three > * {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

/*****
*quick fix
*****/

.cta-custom {
  &:hover {
    text-decoration: none !important;
    background-color: blue !important;
    color: #f0f !important;
  }
}


.wp-block-embed{
  margin: 0;
}
.wp-block-embed__wrapper{
  aspect-ratio: 16 / 9;

  iframe{
    width:100%;
    height:100%;
  }
}

.wp-block-image{

  &.size-large{
    margin: 0;
    width:100%;
    img{
      width:100%; 
    }
  }
}


.page-id-50{
  .image-text{
    .col{
      background-size:contain;
    }
  }
}