.tiles{
    .center{
    @media screen and (max-width: $tabletsize) {
        width: 100%;
        
      }
    }

    .tiles-inner{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        @media screen and (max-width: $tabletsize) {
            display:flex;
            flex-direction:column;
            
          }
        .tile{
            aspect-ratio: 1/1;
            &:nth-child(2n){
                background-color:$lightgrey;
                background-color:#f2f7f6;
            }
            &:hover{
                // background-color:#132a4f;
                // background-color:#367568; 
                // background-color:$lightgreen;
                background-color:$contrastcolor;
                color:white;
                .btn{
                    background-color:$lightgreen;
                    // align-self:flex-end;
                    // background-color:white;
                 }
                 .tile-inner{
                    .tile-icon{
                        background-image: url("/wp-content/themes/acasa/img/icons/icon-arrow-up-green.svg");
                    }
                 }
            }
            .tile-inner{
                padding:20px;
             
                height: calc(100% - 40px);
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                h3{
                    margin-bottom:10px;
                }
                .tile-content{
                    display: flex;
                    flex-direction: column;
                
                }
                .btn{
                   // align-self:flex-end;
                   
                } 
                .tile-icon{
                    background-image: url("/wp-content/themes/acasa/img/icons/icon-arrow-up-blue.svg");
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: center;
                    width:20px;
                    height:20px;
                    align-self: flex-end;
                }
            }
        }
    }
}