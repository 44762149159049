footer {
  //margin-top:100px;
  min-height: 430px;
  // border-top:1px solid $lightgrey;
  background-color: $contrastcolor;

  margin-top: 100px;
  padding-top: 50px;
  padding-bottom: 50px;
  position: relative;

  .footer-inner {
    color: white; 
    display: flex;
    justify-content:space-between;
  flex-wrap: wrap;
    .col{
      width:25%;
      margin:0;
      @media screen and (max-width: $tabletsize) {
        width:50% !important;     
    }
      &:nth-child(1){
        // background-color:red; 
        width:50%;
        @media screen and (max-width: $tabletsize) {
          width:100% !important;     
      }
      }
    }

    a,
    p {
      color: white;
      
      font-size: 14px;
    }

    li {
      padding: 0;
      margin:0;
      margin-bottom:15px;
    }

    h3 {
  
    }

    ul {
      list-style: none;
      padding: 0;
    }
  }
}
