.image-text{
    display: flex; 
    justify-content: space-between;
    @media screen and (max-width: $tabletsize) {
        flex-direction:column;      
    }

    &:nth-child(2n) {
        flex-direction: row-reverse;
        @media screen and (max-width: $tabletsize) {
            flex-direction:column;      
        }
      }
    .col{
        width:50%;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        aspect-ratio: 1/1;
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        @media screen and (max-width: $tabletsize) {
  
           width:100%;
                
        }
        .col-inner{
            width:calc(100% - 40px);
            @media screen and (max-width: $tabletsize) {
                padding-top:30px;
                padding-bottom:30px;         
              }
        }
    } 
} 