.apply {
  background-color: $contrastcolor;
  // border-radius: 20px;
  // height:50vh;
  min-height: 200px;
  width: 100%;
  max-width: 1300px;
  padding-top: 50px;
  padding-bottom: 50px;
  // display:flex;
  // justify-content: space-between;
  // -webkit-box-shadow: 0px 5px 10px -2px #000000;
  // box-shadow: 0px 5px 10px -2px #000000;

  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  color: white;

  margin-top:100px;
  margin-bottom:100px;
  .apply-inner {
    padding: 60px;
    width: calc(100% - 120px);
    .btn{
      background-color:#d3e8db;
      color: #3ca690;
    }
  }

  h2 {
    margin: 0;
  }
}
