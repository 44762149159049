.jobs {

  margin-bottom: 200px;
  .entry {
    border-bottom: 1px solid #ddd;
    // display:flex;
    // justify-content:space-between;
    // flex-wrap:wrap;
    // padding-top:20px;
    // padding-bottom:20px; 
    display: block;

    &:hover{ 
      background-color:#f2f7f6;
      .tag{
        background-color:#fff;
      }
    }
    .entry-inner{
      width: calc(100% - 20px);
      // padding:20px;
      display:flex;
      justify-content:space-between;
      flex-wrap:wrap;
      padding:10px;
    }
    h3{
        margin-top:0;
        width:calc(100% - 40px);
        margin-bottom: 5px;

    }
   
  }
  .entry-icon{
    background-image: url("/wp-content/themes/acasa/img/icons/icon-arrow-up-blue.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width:20px;
    height:20px; 
  }
  
 
}
