.textblocks{
    .textblocks-inner{
        display: flex;
        justify-content: space-between;
        .col{
            width:49%;
            aspect-ratio: 1/1;
          
        }
    }
}