nav{
    background-color:rgba(19, 42, 79, 1); 
   ul li a{ color:white;
    font-weight:200 !important;
  &:hover{
    opacity:.5;
  }
  }
}

nav{
    position: fixed;
    z-index: 2;
    right: -50vw;
    width: 50vw;
    transition: all .2s ease-out;
    height: 100vh;
    top: 0;
    bottom: 0;
    overflow-y: scroll;
    @media screen and (max-width: $tabletsize) {
      right: -100vw;
      width: 100vw;
    }

    &.show{
      right:0;
    }
    ul{
      flex-direction: column;
      margin-top:60px !important; 
      // .menu-hauptmenue-container{
      //   width: 80%;
      //   margin: auto;
      //   margin-top: 80px;
      // }
     
  
    }
    #sidebar-meta{
      width: 80%;
      margin: auto;
      ul {
        display: flex;
        flex-direction:row;
        margin-top: 0px !important;
        li{ 
        margin: 20px;
        @media screen and (max-width: $tabletsize) {
          margin: 15px;
        }
        a{
        font-size:14px;
      }
    }
    }
    }
  }

  nav {
    ul {
      padding: 0;
      margin: 0;
      display: flex;
      li {
        margin: 20px;
        list-style: none;
        @media screen and (max-width: $tabletsize) {
        margin: 15px;
        }
        a{
          text-decoration: none;
          // text-transform: uppercase;
          // color: #65B6A5;
          letter-spacing: .4pt;
          color: white;
          font-size: 36px;
          font-family: "Roboto Condensed";
          font-weight: 400;
          @media screen and (max-width: $tabletsize) {
            font-size: 24px;
          }
        }
      }
      &.sub-menu{
        display:none;
      }
    }
  }

#menu-hauptmenue {
  width: 80%;
  margin: auto;
} 


.overlay{
    &.is-visible{
    background-color:rgba(255,255,255,0.6);
    position:fixed;
    z-index:1;
    top:0;
    left:0;
    right:0;
    bottom:0;
    }
}