.blogposts {
    padding-top: 100px;
    padding-bottom: 100px;
  
  
    .thumbnail{
      img{
        width:100%;
        height:auto;
        margin-bottom:20px; 
      }
    }
  
    .center{
        background-color: $contrastcolor; 
    }

  
  
    .grid-container {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 30px; /* Abstand zwischen den Spalten und Zeilen */
      @media screen and (max-width: $desktopsize) {
        grid-template-columns: repeat(2, 1fr); 
      } 
  
      @media screen and (max-width: $tabletsize) {
        display:flex;
        flex-wrap:wrap;
        gap:0; 
      }  
      .grid-item {
          height: 100%;
          display: flex; 
          @media screen and (max-width: $tabletsize) {
          width:100%; 
          margin-bottom:30px; 
          } 
    
      }
    }
  
    //preview
    .flex-container {
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
  
      .flex-item-first{
        h2{ 
            line-height: normal;
            margin: 0;
            color:white;
          }
        width: calc(33.3% - 30px);
        @media screen and (max-width: $tabletsize) {
          width: 100%;
          } 
      }
      .flex-item {
        width: calc(33.3% - 30px);
        height: 100%;
        display: flex;
  
        @media screen and (max-width: $tabletsize) {
          width: calc(50% - 30px);
          }  
          @media screen and (max-width: $mobilesize) {
            width: 100%; 
            }  
      
    
        &:first-child {
          width: 50%;
        }
        &:last-child {
          margin-top: 50px;
          a {
            min-height: 420px;
          }
        }
      }
    }
  
    ul,
    li {
      padding: 0;
      margin: 0;
      list-style: none;
      &:before{
        display:none; 
      }
    }
    a.post-card{ 
        color:black !important; 
    }
    .post-card {
      // width:25%;
      // height:100px;
  
      min-height: 350px;
      display: block;
      background-color: $contrastcolor;
    //    background-color: $lightblue;
      background-color: white;
      text-decoration: none;
      padding: 20px;
   
      &:hover{
        // background-color: $blue;
        color:black;
      }
  
      .post-card-inner {
        height: 100%;
        display: flex;
        // justify-content: space-between;
        flex-direction: column;
          // color: $white;
        // color:white;
        h3 {
          margin: 0;
          margin-bottom: 10px;
          min-height: 50px;
        }
        .excerpt {
          flex-grow: 1;
          p {
            display: -webkit-box;
            -webkit-line-clamp: 3; /* Zeilenanzahl, nach der der Text gekürzt wird */
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis; 
            // opacity: 0.5;
            // color:$corporatecolor;
          }
        }
        .headline{
          // color: white;
      /* font-weight: 400; */
      text-decoration: underline;
        }
        .link {
          // align-self:flex-end;
        }
      }
    }
  }
  
  .flex-item .publication-card{
    &:hover{ 
        background-color: $contrastcolor;
    }
  
  }
  
  
  
  .single-publikationen{
  .post-thumbnail{
    padding-top:90px; 
    margin-bottom:50px;
    img{
      width:100%;
      height:auto;
    }
  
  }
  }
  
  .post-meta{
    display: flex;
    justify-content: space-between;
    opacity: .5;
    margin-bottom: 10px;
    text-decoration: none !important;
    font-size:14px;  
  }